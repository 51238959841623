import { Logo } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

type Variants = 'dark' | 'light'

const LemonLogo = styled(Logo)<{ colorVariant: Variants }>(
  ({ colorVariant, theme: { colors } }) => css`
    color: ${colorVariant === 'light'
      ? colors.primary.main
      : colors.neutral.lightest};
  `,
)

export default LemonLogo
