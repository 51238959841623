import { Link } from '@remix-run/react'
import type { StyledComponentPropsWithRef } from 'styled-components'

import type { Variants } from '../../__components/Header'

import LemonLogo from '~/components/LemonLogo'
import paths from '~/constants/paths'

export type LogoVariant = StyledComponentPropsWithRef<
  typeof LemonLogo
>['variant']

const HeaderLogo = ({
  variant,
  logoVariant = 'symbol',
}: {
  variant: Variants
  logoVariant?: LogoVariant
}) => {
  return (
    <Link to={paths.ACQUISITION_FLOW_HOME}>
      <LemonLogo variant={logoVariant} size="md" colorVariant={variant} />
    </Link>
  )
}

export default HeaderLogo
